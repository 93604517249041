<template>
  <div class="register-page">
    <div class="top-banner">
      <div class="top-main">
        <div class="header flex-center-between">
          <div class="logo flex-center-start">
            <img :src="logo || logoUrl" alt="" />
            <span class="app-name">{{ appName }}</span>
          </div>
          <div class="lang-box">
            <el-dropdown trigger="click">
              <div class="lang-info flex-center">
                <img :src="langIcon" alt="" />
                <i
                  class="el-icon-caret-bottom"
                  style="color: #fff; font-size: 14px"
                ></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="lang-dropdown">
                <el-dropdown-item v-for="(item, i) in langList" :key="i">
                  <div
                    class="flex-center-start lang-menu"
                    @click="changeLang(item)"
                  >
                    <img :src="item.icon" alt="" />
                    <span>{{ item.name }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div class="title">{{ pageTitle }}</div>
      </div>
    </div>

    <div class="main-form mb-50">
      <router-view></router-view>
    </div>

    <footerBar></footerBar>
  </div>
</template>
<script>
import { login, register } from '@/api/login'
import { setToken, setUser, setUserId } from '@/utils/auth'
import footerBar from '@/components/footer'
export default {
  components: {
    footerBar
  },
  data() {
    return {
      logoUrl: require('@/assets/imgs/logo.png'),
      demoUrl1: require('@/assets/imgs/upload-demo1.png'),
      demoUrl2: require('@/assets/imgs/upload-demo2.png'),
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      pageTitle: ''
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    },
    appName() {
      return this.$store.state.appName
    },
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  watch: {
    $route() {
      this.changeRoute()
    }
  },
  mounted() {
    this.changeRoute()
  },
  methods: {
    toPath(path) {
      this.$router.push(path)
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    },
    changeRoute() {
      let name = this.$route.name
      switch (name) {
        case 'privacy':
          this.pageTitle = this.$t('tiao-kuan-he-tiao-jian')
          break
        case 'refundRole':
          this.pageTitle = this.$t('tui-huo-chan-pin-zhi-chi-he-wei-xiu')
          break
        case 'delivery':
          this.pageTitle = this.$t('song-huo-he-qu-huo')
          break
        case 'shippingPolicy':
          this.pageTitle = this.$t('mai-jia-zheng-ce')
          break
        case 'about':
          this.pageTitle = this.$t('guan-yu-wo-men')
          break
        case 'statement':
          this.pageTitle = this.$t('xian-dai-nu-li-sheng-ming')
          break
      }
    }
  }
}
</script>
